import * as React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import {StaticImage, GatsbyImage} from "gatsby-plugin-image"
import {SectionHero, Section, SectionTitle, SectionDescription, SectionPageTitle, Container} from "../components/Section"
import SearchBar from "../components/SearchBar"


const SectionHeros = styled(SectionHero)`
	position:relative;
`
const HeroBanner = styled.div`	
	position:relative;
    &:after, &:before{
        display:block;
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
    }
	&:after{
		width:60%;
		background-color: rgba(255,255,255,0.20);		
        clip-path: ellipse(25% calc(50% + 170px) at 0 50%);
	}    
	&:before{        
		width:40%;
        background: transparent linear-gradient(270deg, #1383C1 0%, #006EAC 100%) 0% 0% no-repeat padding-box;			
        clip-path: ellipse(25% 60% at 0 50%);
		z-index:1;
        opacity:0.8;
	}
    .gatsby-image-wrapper {
        @media (min-width:992px){
            height: calc(100vh - 100px);
        }
    }
`
const HeroCaption = styled.div`	
    padding:40px 0;
    @media (min-width:768px){
        position:absolute;
        top:0;
        left:0;
        width: 100%;
    }
    @media (min-width:992px){
        padding:60px 0;
    }
    @media (min-width:1600px){
        padding:80px 0;
    }
    .container{
        margin:0 auto;
        max-width:1080px;
        @media (min-width: 1440px) {
            max-width:1100px;
        }
        @media (min-width: 1600px) {
            max-width:1360px;
        }
    }
	
`

const HeroDesc = styled(SectionDescription)`
    font-style:italic;
    font-weight:500;
    margin-bottom:0;
`
const HeroTitle = styled(SectionPageTitle)`
    margin-bottom:0;
    
`

const HeroSearch = styled.div`
    @media (min-width:992px){
        min-height: 78px;
        margin-top:-78px;
    }
    @media (min-width:1600px){
        min-height:100px;
        margin-top:-100px;
    }
    .search-bar{
        @media (min-width:992px){
            border-radius: 15px 15px 0 0;
        }
        @media(min-width:1200px){
            border-radius: 20px 20px 0 0;
        }
    }
    .container{
        @media (max-width:991.98px){
            padding:0;
        }
    }
`

const HeroCaptionGrid = styled.div`
	display:flex;
    flex-wrap:wrap;
    align-items: baseline;
    margin:0 -20px;
`
const HeroCaptionLeft = styled.div`
    position:relative;
    width:100%;
    padding:0 20px;
    @media (min-width:768px){
        flex: 0 0 41.666667%;
        max-width:41.666667%;
    }
`
const HeroCaptionRight = styled.div`
    position:relative;
    width:100%;
    padding:0 20px;
    @media (min-width:768px){
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }
`

const Sections = styled(Section)` 
    .section-vertical{

    }
    .section-boxed{

    }
    .section-regular{

    }
`
const Containers = styled(Container)` 
    z-index:2;
    padding:40px 15px 100px;
    
    @media(min-width:768px){  
        padding:60px 15px 100px;
    }
    @media(min-width:992px){
        padding:80px 30px 100px;
    }
    @media(min-width:1440px){
        padding:110px 30px 200px;
    }
    @media(min-width:1600px){
        padding:150px 30px 230px;
    }
    &:after {
        display:block;
        font-family:"Teko", Sans-serif;
        content: attr(data-shadowtext);
        width: 100%;
        position:absolute;
        top:auto;
        bottom:0;
        left: 50%;
        z-index:1;
        transform: translateX(-50%);
        text-align: center;
        color:${props=>props.afterColor};
        text-transform: uppercase;
        font-weight: 900;    

        font-size: 34px;
        line-height: 15px;
        @media (min-width: 576px) {  
            font-size: 50px;
            line-height: 25px;
        }
        @media (min-width: 768px) {  
            font-size: 70px;
            line-height: 35px;
        }
        @media (min-width: 992px) {  
            font-size: 100px;
            line-height: 50px;
        }
        @media (min-width: 1200px) {  
            font-size: 120px;
            line-height: 60px;
        }
        @media (min-width: 1600px) {
            font-size: 160px;
            line-height: 80px;
        }
        @media (min-width: 1900px) {
            font-size: 170px;
            line-height: 85px;
        }
    }

    &.container-vertical{        
        &:after {
            @media (min-width: 1440px) {  
                font-size: 140px;
                line-height: 65px;
            }
            @media (min-width: 1600px) {
                font-size: 160px;
                line-height: 80px;
            }
            @media (min-width: 1900px) {
                font-size: 170px;
                line-height: 85px;
            }
        }
    }
    &.container-regular{
        &:after {
            @media (min-width: 1440px) {  
                font-size: 140px;
                line-height: 65px;
            }
            @media (min-width: 1600px) {
                font-size: 160px;
                line-height: 80px;
            }
            @media (min-width: 1900px) {
                font-size: 170px;
                line-height: 85px;
            }
        }
    }
`
const SectionGrid = styled.div`
    display:flex;
    flex-wrap:wrap;
    align-items:center;
    margin:0 -15px;
`
const SectionGridItem = styled.div`
    position:relative;
    width:100%;
    padding:0 15px;
    @media (min-width: 992px) { 
        max-width:50%;
        flex:0 0 50%;
    }
`
const ThumbnailRoof = styled.div`
    position:relative;
    @media (min-width: 992px) { 
        padding-left: 50px;
    }
    
`
const ThumbnailFigure = styled.div`
    box-shadow:0 0 99px rgba(0,0,0,0.4);
    border-radius:20px;            
    .gatsby-image-wrapper{
        img{
            border-radius:20px;            
        }
    }
    
`
const SmileFace = styled.div`
    background-color:#fff;
    position:absolute;
    z-index:1;
    border-radius:50%;
    display:flex;
    align-items:center;    
    justify-content:center;
    animation: ripplelgwhite 0.7s linear infinite;
    left:50%;
    transform:translateX(-50%);
    width:60px;
    height:60px;
    bottom:-30px;
    @media (min-width: 992px) { 
        width:100px;
        height:100px;
        top:50%;
        left:0;
        transform:translateY(-50%);
        bottom:auto;       
    }
`
const RoofStyle = ({ location, data }) => (
  <Layout location={location}>
    <Seo
        title={data.allContentfulPageData.edges[0].node.metaTitle}
        description={data.allContentfulPageData.edges[0].node.metaDescription.metaDescription}
    />
    <SectionHeros className="section-hero">
        <HeroBanner>
            <StaticImage src="../images/roof-hero.png" alt="roof hero" />
        </HeroBanner>
        <HeroCaption>
            <div className="container">		
                <HeroCaptionGrid>
                    <HeroCaptionLeft><HeroTitle className="h2" >We offer <br /><mark>three</mark> different <mark>roof styles</mark> for your metal building.</HeroTitle></HeroCaptionLeft>
                    <HeroCaptionRight><HeroDesc><p>You can choose which is best for you based upon your budget, intended use, size of the building, and installation location. If your area tends to experience high winds, rain, and heavy debris, we recommend the Vertical Roof option. We also recommend the Vertical Roof if your building is wider than 24’ or longer than 40’.</p></HeroDesc></HeroCaptionRight>
                </HeroCaptionGrid>
            </div>
        </HeroCaption>
        <HeroSearch>
            <div className="container"><SearchBar /></div>
        </HeroSearch>
    </SectionHeros>
    {
      data.allContentfulRoofStyle.edges.map((roof, i) => (
        <Sections pt="0" pb="0" bgColor={roof.node.sectionBgColor} className={`section-${roof.node.name.toLowerCase()}`} >
          <Containers className="container-vertical" data-shadowtext={`${roof.node.name} Roof Style`} afterColor="rgba(34,201,232,0.1)">
            <SectionGrid>
              <SectionGridItem>
                <SectionTitle mb="0">{roof.node.name}</SectionTitle>
                <SectionDescription zIndex="2">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: roof.node.description.childMarkdownRemark.html,
                    }}
                  />
                </SectionDescription>
              </SectionGridItem>
              <SectionGridItem>
                <ThumbnailRoof>
                  <SmileFace><GatsbyImage image={roof.node.icon.gatsbyImageData} alt={roof.node.name} /></SmileFace>
                  <ThumbnailFigure>
                    <GatsbyImage image={roof.node.image.gatsbyImageData} alt={roof.node.name} />
                  </ThumbnailFigure>
                </ThumbnailRoof>
              </SectionGridItem>
            </SectionGrid>
          </Containers>
        </Sections>
      ))
    }
  </Layout>
)
export default RoofStyle

export const pageQuery = graphql`
  query RoofStylePageQuery{
    allContentfulPageData(filter: {page: {eq: "Roof Style"}}) {
      edges {
        node {
          metaTitle
          metaDescription {
            metaDescription
          }
        }
      }
    }
    allContentfulRoofStyle(sort: {fields: order}) {
      edges {
        node {
          name
          description {
            childMarkdownRemark {
              html
            }
          }
          order
          image {
            gatsbyImageData(quality: 100)
          }
          icon {
            gatsbyImageData(quality: 100)
          }
          sectionBgColor
        }
      }
    }
  }
`